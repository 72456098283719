import React from 'react';
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from 'src/functions/localStorageHandler';
import { StatsigProvider, StatsigUser } from 'statsig-react';
import { v4 } from 'uuid';

const FIRST_TOUCH_TEXT = 'first time visitor';

interface VisitedPath {
  propertyName: string;
  propertyValue: string;
}

const VISITED_PATHS_TO_TRACK: Record<string, VisitedPath> = {
  '/reviews': {
    propertyName: 'visitedReviews',
    propertyValue: 'viewed Reviews page',
  },
};

const StatsigProviderMask: React.FC<any> = ({ children }) => {
  const getCurrentPathTrackCustomProperty = (path: string) => {
    if (!path) return {};

    if (VISITED_PATHS_TO_TRACK[path]) {
      const { propertyName, propertyValue } = VISITED_PATHS_TO_TRACK[path];

      const visitedPath = {
        [propertyName]: propertyValue,
      };

      setLocalStorage(`visitedPath:${propertyName}`, JSON.stringify(visitedPath));

      return visitedPath;
    }

    return {};
  };

  const getPersistedPathTrackCustomProperty = () => {
    const visitedPaths: Record<string, string> = {};

    Object.values(VISITED_PATHS_TO_TRACK).forEach(({ propertyName }) => {
      const visitedPathFromStorage = getLocalStorage(`visitedPath:${propertyName}`);

      if (!visitedPathFromStorage) return;

      const { [propertyName]: propertyValue } = JSON.parse(visitedPathFromStorage);

      visitedPaths[propertyName] = propertyValue;
    });

    return visitedPaths;
  };

  const user = (() => {
    if (typeof window === 'undefined') return null;

    const user = {} as StatsigUser;

    const statsigUserId = getLocalStorage('statsigUserId');

    const visitedPathsCustomProperty = {
      ...getPersistedPathTrackCustomProperty(),
      ...getCurrentPathTrackCustomProperty(window.location.pathname),
    };

    if (statsigUserId) {
      user.userID = statsigUserId;
      let customProperties = {};

      if (getSessionStorage('@statsig:firstTouch') === 'true') {
        customProperties = {
          firstTouch: FIRST_TOUCH_TEXT,
        };
      }

      customProperties = {
        ...customProperties,
        ...visitedPathsCustomProperty,
      };

      user.custom = customProperties;

      return user;
    }

    const newUserId = (window as any).v4 ? (window as any).v4() : v4();
    setLocalStorage('statsigUserId', newUserId);
    setSessionStorage('@statsig:firstTouch', 'true');

    return {
      userID: newUserId as string,
      custom: {
        firstTouch: FIRST_TOUCH_TEXT,
        ...visitedPathsCustomProperty,
      },
    };
  })();

  return (
    <StatsigProvider
      sdkKey={process.env.GATSBY_STATSIG_SDK_KEY || 'client-yMNyryucOI2ddIofgqohKtiJTW1PMsiovM05eN1gKDi'}
      user={user}
      waitForInitialization={false}
    >
      {children}
    </StatsigProvider>
  );
};

export default StatsigProviderMask;
