import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'src/functions/localStorageHandler';
import { TopBarInfoLayouts } from 'src/organisms/Shared/Layout/TopInfoBar/TopInfoBar';
import { Color } from 'src/styles/colors';
import { FlattenSimpleInterpolation } from 'styled-components';

interface Options {
  timeToHide?: number;
  contentLayout: TopBarInfoLayouts;
  dismissible?: boolean;
  dismissButtonColor?: Color;
  permanentDismissKey?: string;
  backgroundColor?: Color;
  customStyles?: FlattenSimpleInterpolation;
}

interface TopInfoBarControllerContextData {
  show(options: Options): void;
  hide(setDismissedKey?: boolean): void;
  isVisible: boolean;
  content: TopBarInfoLayouts;
  dismissible?: boolean;
  dismissButtonColor?: Color;
  backgroundColor?: Color;
  customStyles?: FlattenSimpleInterpolation;
}

const TopInfoBarControllerContext = createContext<TopInfoBarControllerContextData>(
  {} as TopInfoBarControllerContextData
);

interface TopInfoBarState {
  isVisible: boolean;
  content: TopBarInfoLayouts;
  dismissible?: boolean;
  dismissButtonColor?: Color;
  backgroundColor?: Color;
  customStyles?: FlattenSimpleInterpolation;
  permanentDismissKey?: string;
}

const RESET_STATE: TopInfoBarState = {
  isVisible: false,
  content: null,
  dismissible: false,
  backgroundColor: null,
  dismissButtonColor: null,
  customStyles: null,
  permanentDismissKey: null,
};

const TopInfoBarControllerProvider: React.FC<any> = ({ children }) => {
  const [topInfoBarState, setTopInfoBarState] = useState<TopInfoBarState>(RESET_STATE);

  const timeoutReference = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    // Clear timeout reference after component unmount
    return () => {
      if (timeoutReference.current) clearTimeout(timeoutReference.current);
    };
  }, []);

  const getStorageKey = (key: string) => `topInfoBar@${key}`;

  const getValueFromStorage = (key: string) => getLocalStorage(getStorageKey(key));
  const setValueToStorage = (key: string, value: string) => setLocalStorage(getStorageKey(key), value);

  const show = (options: Options) => {
    const {
      timeToHide,
      contentLayout,
      dismissible,
      backgroundColor,
      dismissButtonColor,
      customStyles,
      permanentDismissKey,
    } = options;

    if (permanentDismissKey && getValueFromStorage(permanentDismissKey) === 'true') return;

    setTopInfoBarState({
      isVisible: true,
      content: contentLayout,
      dismissible,
      backgroundColor,
      dismissButtonColor,
      customStyles,
      permanentDismissKey,
    });

    if (timeToHide) {
      timeoutReference.current = setTimeout(hide, timeToHide);
    }
  };

  const hide = (setDismissedKey = true) => {
    if (topInfoBarState.permanentDismissKey && setDismissedKey) {
      setValueToStorage(topInfoBarState.permanentDismissKey, 'true');
    }

    // removing content avoids jumping when the bar is hidden non-permanently
    setTopInfoBarState((prev) => ({ ...prev, isVisible: false, content: null }));
  };
  const { isVisible, content, dismissible, backgroundColor, dismissButtonColor, customStyles } = topInfoBarState;

  return (
    <TopInfoBarControllerContext.Provider
      value={{
        show,
        hide,
        isVisible,
        content,
        dismissible,
        backgroundColor,
        dismissButtonColor,
        customStyles,
      }}
    >
      {children}
    </TopInfoBarControllerContext.Provider>
  );
};

const useTopInfoBarController = (): TopInfoBarControllerContextData => {
  const context = useContext(TopInfoBarControllerContext);

  if (!context) {
    throw new Error('useTopInfoBarController must be used within a TopInfoBarControllerProvider');
  }

  return context;
};

export { useTopInfoBarController, TopInfoBarControllerProvider };
